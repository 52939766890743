'use strict'
// common
import ScrollFade from './common/ScrollFade';
import SmoothScroll from './common/SmoothScroll';
import Tab from './common/Tab';
import Accordion from './common/Accordion';
import AlertIe from './common/AlertIe';
import FixedHeader from './components/FixedHeader';
import AddressSearch from './components/AddressSearch';
import ToPageTop from './components/ToPageTop';
import RecommendSlider from './components/RecommendSlider';
import SpNavigation from './components/SpNavigation';
import SpSideNavigation from './components/SpSideNavigation';
import SpFooterNavigation from './components/SpFooterNavigation';
import SideNavigationCurrent from './components/SideNavigationCurrent';
import TabAccordion from './components/TabAccordion';
import HeaderSearch from './components/HeaderSearch';
import FormAgree from './components/FormAgree';
import ProductSearch from './components/ProductSearch';
import PartnerNews from './components/PartnerNews';
import PartnersMoreButton from './components/PartnersMoreButton';
import Recruit from './components/Recruit';
import SelectboxAnchor from './components/SelectboxAnchor';
import tableProductsHeaderSticky from './components/tableProductsHeaderSticky';

// index
import ExistingJs from './components/ExistingJs';
import TopKvSlider from './components/TopKvSlider';
import TopAppealSlider from './components/TopAppealSlider';

export default class Main {
  constructor() {
    this.init();
  }

  init() {
    new TopKvSlider('.kv__slider');
    new TopAppealSlider('.appeal__slider');
    new RecommendSlider('.c-recommend__slider');
    AddressSearch();
    FixedHeader();
    HeaderSearch();
    SpNavigation();
    SpSideNavigation();
    SpFooterNavigation();
    FormAgree();
    ProductSearch();
    PartnerNews();
    PartnersMoreButton();
    ScrollFade();
    SideNavigationCurrent();
    SmoothScroll();
    Tab();
    Accordion();
    TabAccordion();
    ToPageTop();
    ExistingJs();
    Recruit();
    SelectboxAnchor();
    AlertIe();
    tableProductsHeaderSticky();
  }
}


window.addEventListener('DOMContentLoaded', () => {
  new Main()

})
