export default() => {
// 新着情報
  // TOP 更新情報が5件以上の処理
  const partnersInfo = document.getElementById('partnersInfo');
  if(partnersInfo) {
    const moreButton = partnersInfo.querySelector('.c-more');
    const newsBody = partnersInfo.querySelector('.c-info__bodyInner');
    const value = newsBody.getAttribute('data-value');
    const newsBodyAll = newsBody.clientHeight;
    const newsItems = newsBody.querySelectorAll('a');
    const newsDefaultItems = newsBody.querySelectorAll(`a:nth-child(-n+${value}`);

    // "valueの値"件以上で「もっと見るボタン」表示
    if(newsItems.length > value) {
      moreButton.classList.remove('hidden')
    }

    // "valueの値"件分の高さを取得して最新"valueの値"件のみ表示
    let newsDefaultHeight = 0;
    for (let i = 0; i < newsDefaultItems.length; i++) {
      newsDefaultHeight += newsDefaultItems[i].clientHeight;
    }
    newsBody.style.height = (newsDefaultHeight + (20 * value) - 5) + 'px'; // 20はmarginの値。-5は調整。
    // 「もっと見る」で全件表示, 「閉じる」で元に戻す
    moreButton.addEventListener('click', ()=>{
      if(!moreButton.classList.contains('close')) {
        newsBody.style.height = newsBodyAll + 'px';
        moreButton.textContent = '閉じる';
        moreButton.classList.add('close');
      } else if(moreButton.classList.contains('close')) {
        newsBody.style.height = (newsDefaultHeight + (20 * value) - 5) + 'px'; // 20はmarginの値。-5は調整。
        moreButton.textContent = 'もっと見る';
        moreButton.classList.remove('close');
      }
    })

    const breakpoint = window.matchMedia("(min-width:768px)");
    breakpoint.addEventListener("change", () => {
      window.location.reload();
    });
  }

// パートナーズ倶楽部とは
  const partnersAppeal = document.getElementById('partnersAppeal');
  if(partnersAppeal) {
    const moreButton = partnersAppeal.querySelector('.c-more');
    const target = partnersAppeal.querySelector('.partners-appeal__textBlock');
    const targetHeight = target.clientHeight;
    // 最初の5行分の高さのみ表示
    const Height = window.innerWidth < 769 ? '9em' : '5em';
    target.style.height = Height;
    // 「もっと見る」で全文表示, 「閉じる」で元に戻す
    moreButton.addEventListener('click', ()=> {
      if(!moreButton.classList.contains('close')) {
        target.style.height = targetHeight + 'px';
        moreButton.textContent = '閉じる';
        moreButton.classList.add('close');
      } else if(moreButton.classList.contains('close')) {
        target.style.height = Height;
        moreButton.textContent = 'もっと見る';
        moreButton.classList.remove('close');
      }
    })

  }



}
