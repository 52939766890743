export default function tableProductsHeaderSticky() {

  // 品番検索改修 追加
  function searchProductsTable() {
    // テーブルとその横幅を取得
    var table = $("#searchProductsResultTable");
    var tableWidth = table.width();
    
    // thead とその縦幅を取得
    var thead = table.find("thead");
    var theadHeight = thead.outerHeight();
  
    // thead 内の th を取得
    var theadTh = thead.find("th");
    theadTh.css("height", theadHeight);
  
    // tbody を取得
    var tbody = table.find("tbody");
  
    // 追従するグロナビの高さを取得
    var navHeight = $(".l-header__container").innerHeight();
  
    // テーブルを横スクロールした場合に sticky で固定していた th 要素の幅と高さを指定する関数
    var thWidthSum;
    var thWidthSumAdjust = $(window).width() > 768 ? 7 : 3;
  
    function stickyToFixedSize() {
      var repeat = $(window).width() > 768 ? 3 : 2;
  
      for (var i = 0; i < repeat; i++) {
        $(theadTh[i]).css({
          height: theadHeight,
        });
  
        // fixed で浮かした分の横幅合計を取得する
        thWidthSum = $(theadTh[i]).width();
        thWidthSum += thWidthSum - 15;
      }
    }
  
    // テーブルの横スクロール位置を取得
    var tableWrap = $('.c-search-products__resultTableWrap');
    var scrollFlag = false;
    var scrollLeft;
    tableWrap.on("scroll", function() {
      scrollLeft = $(this).scrollLeft();
      scrollFlag = true;
    });
  
    // 左側の余白を設定
    var leftSpace;
    var ww = $(window).width();
  
    if (ww > 0 && ww < 769) {
      leftSpace = 17;
    }
    
    if (ww > 768 && ww < 1081) {
      leftSpace = 17;
    }
  
    if (ww > 1080 && ww < 1151) {
      leftSpace = 37;
    } 
  
    if (ww > 1150) {
      leftSpace = 49;
    }
  
    window.addEventListener("scroll", function() {
      // ページ上部を取得
      var windowTop = $(window).scrollTop() + navHeight;
      
      // テーブルの位置を取得
      var tableTop = table.offset().top;
      var tableBottom = tableTop + table.innerHeight() - theadHeight;
  
      // リセット用
      function reset() {
        table.attr("style", "");
        thead.attr("style", "").removeClass("is-fixed");
      }
  
      // テーブルが画面上に表示されている場合
      if (windowTop > tableTop) {
        table.css({
          width: tableWidth,
          paddingTop: theadHeight,
          "table-layout": "auto",
        });
  
        thead.addClass("is-fixed");
  
        thead.css({
          position: "fixed",
          top: navHeight,
          left: -scrollLeft + leftSpace,
          zIndex: 8,
          width: tableWidth,
          borderBottom: "1px solid #b1b1b1",
        });
  
        tbody.css("width", tableWidth);
  
        // すでに横スクロールがされていた場合は先に thead に .is-scroll を付与する
        if (scrollFlag) {
          thead.addClass("is-scroll");
          stickyToFixedSize();
          thead.css("padding-left", thWidthSum + thWidthSumAdjust);
        }
  
        // テーブルヘッダーとテーブルのスクロール位置を同期
        tableWrap.on("scroll", function() {
          thead.addClass("is-scroll");
          stickyToFixedSize();
          thead.css("padding-left", thWidthSum + thWidthSumAdjust);
  
          scrollLeft = $(this).scrollLeft();
  
          thead.css({
            left: -scrollLeft + leftSpace,
          });
        });
  
      // テーブルが画面上から見えなくなった場合
      } else {
        reset();
      }
  
      // テーブルが画面上に表示されていない場合
      if (windowTop > tableBottom) {
        reset();
      }
    });
  }
  
  searchProductsTable();
  
  // 品番検索のテーブルスクロール位置を記憶する
  function saveTableScrollPosition() {
    var tableWrap = $('.c-search-products__resultTableWrap');
    var tableWrapId = tableWrap.attr('id');
    var currentURL = removeURLParameters(window.location.href);
    
    // スクロール位置の復元
    var savedScrollPosition = getSavedScrollPosition(currentURL, tableWrapId);
    if (savedScrollPosition !== null) {
      tableWrap.scrollLeft(savedScrollPosition);
    }
    
    // スクロール位置の保存
    tableWrap.on('scroll', function() {
      var scrollPosition = tableWrap.scrollLeft();
      saveScrollPosition(currentURL, tableWrapId, scrollPosition);
    });
    
    // URLの保存
    saveTableURL(tableWrapId, currentURL);
    
    // URLごとのスクロール位置の復元
    var savedTableURL = getSavedTableURL(tableWrapId);
    if (savedTableURL === currentURL) {
      var savedScrollPosition = getSavedScrollPosition(currentURL, tableWrapId);
      if (savedScrollPosition !== null) {
        tableWrap.scrollLeft(savedScrollPosition);
      }
    }
    
    // #btn-search-product クリック時の処理
    $('#btn-search-product').on('click', function() {
      clearSavedScrollPosition(currentURL, tableWrapId);
    });
    
    function removeURLParameters(url) {
      return url.split('?')[0];
    }
    
    function saveScrollPosition(url, tableWrapId, scrollPosition) {
      var key = 'scrollPosition_' + url + '_' + tableWrapId;
      localStorage.setItem(key, scrollPosition);
    }
    
    function getSavedScrollPosition(url, tableWrapId) {
      var key = 'scrollPosition_' + url + '_' + tableWrapId;
      var savedScrollPosition = localStorage.getItem(key);
      return savedScrollPosition !== null ? parseInt(savedScrollPosition) : null;
    }
    
    function saveTableURL(tableWrapId, url) {
      var key = 'tableURL_' + tableWrapId;
      localStorage.setItem(key, url);
    }
    
    function getSavedTableURL(tableWrapId) {
      var key = 'tableURL_' + tableWrapId;
      return localStorage.getItem(key);
    }
    
    function clearSavedScrollPosition(url, tableWrapId) {
      var key = 'scrollPosition_' + url + '_' + tableWrapId;
      localStorage.removeItem(key);
    }
  }
  
  saveTableScrollPosition();
}