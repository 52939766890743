export default () => {
  const headerElem = document.getElementById('header');
  const headerContainer = document.querySelector('.l-header__container');
  const options = {
    root: null,
    rootMargin: '-72px 0px 0px 0px',
    threshold: 0,
  }

  if (headerContainer) {
    const onIntersect = (entries) => {
      if (!entries[0].isIntersecting) {
        headerContainer.classList.add('is-fixed');
      } else {
        headerContainer.classList.remove('is-fixed');
      }
    }
    const io = new IntersectionObserver(onIntersect, options);
    io.observe(headerElem);
  }

  // パートナーズ倶楽部 - PCヘッダー専用
  const partnersHeaderNavPc = document.querySelector('.partners-header__nav--pc');
  if(partnersHeaderNavPc && window.innerWidth >= 769) {
    let scrollPoint = 0;
    let lastPoint = 0;

    window.addEventListener("scroll",function(){
      scrollPoint = window.scrollY;
      const header = document.getElementById('header');
      const headerHeight = header.offsetHeight;

      if (scrollPoint > headerHeight) {
        if(scrollPoint > lastPoint){
          partnersHeaderNavPc.classList.add('fixed-hide');
        }else{
          partnersHeaderNavPc.classList.remove('fixed-hide');
        }
        lastPoint = scrollPoint;
      }
    });
  }
}
