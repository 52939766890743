export default() => {
  // const searchBtn = document.getElementById('searchBtn');

  // if(searchBtn) {
  //   searchBtn.addEventListener('click', ()=>{
  //     let api = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode=';
  //     let error = document.getElementById('error');
  //     let input = document.getElementById('input');
  //     // let address1 = document.getElementById('address1');
  //     let address2 = document.getElementById('address02');
  //     let param = input.value;
  //     let url = api + param;

  //     fetchJsonp(url, {
  //         timeout: 10000,
  //       })
  //       .then((response)=>{
  //         error.textContent = '';
  //         return response.json();
  //       })
  //       .then((data)=>{
  //         if(data.status === 400){ //エラー時
  //           console.log("oya");
  //           error.textContent = data.message;
  //         }else if(data.results === null){
  //           console.log("hate");
  //             error.textContent = '郵便番号から住所が見つかりませんでした。';
  //         } else {
  //             // address1.value = data.results[0].address1;
  //             address2.value = data.results[0].address2;
  //         }
  //       })
  //       .catch((ex)=>{
  //         console.log(ex);
  //       });
  //   }, false);
  // }
}